import React, { useState } from 'react';
import { Card, Button } from 'react-bootstrap';

const CollapsibleCard = ({ title, children }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const toggle = () => {
    setIsCollapsed(!isCollapsed);
  };
  return (
    <>
      <Card>
        <Card.Body>
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="mb-0 fs-0">{title}</h5>
            <div className="d-flex align-items-end">
              <Button variant="falcon-primary" size="sm" onClick={toggle}>
                {isCollapsed ? 'Hide' : 'Show'}
              </Button>
            </div>
          </div>
          {isCollapsed && <div className="mt-3">{children}</div>}
        </Card.Body>
      </Card>
    </>
  );
};

export default CollapsibleCard;
