import React from 'react';
import { Col, Row } from 'react-bootstrap';

const Header = () => {
  return (
    <Row className="d-flex justify-content-between">
      <Col>
        <h5 className="mb-0 fs-0">Executions</h5>
      </Col>
    </Row>
  );
};

export default Header;
