import React, { useState, useEffect } from 'react';
import { getUser } from 'helpers/requests/users';
import { Card, Placeholder } from 'react-bootstrap';

const Profile = () => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});

  const handleGetUser = async () => {
    const r = await getUser();
    if (r.success) {
      setUser(r.response.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetUser();
  }, []);

  if (loading) {
    return (
      <Card className="mb-3">
        <Card.Header>
          <Placeholder as={Card.Text} animation="glow">
            <Placeholder xs={2} />
          </Placeholder>
        </Card.Header>
        <Card.Body className="pt-0">
          <Placeholder as={Card.Text} animation="glow">
            <Placeholder xs={2} />
          </Placeholder>
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card className="mb-3">
      <Card.Header>
        <div className="d-flex justify-content-between">{user.full_name}</div>
      </Card.Header>
      <Card.Body className="pt-0">
        <small>{user.email}</small>
      </Card.Body>
    </Card>
  );
};

export default Profile;
