import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from 'App';
import 'helpers/initFA';

const container = document.getElementById('main');
const root = createRoot(container);

root.render(
  // When StrictMode is enabled, the `isMounted.current` logic won't work.
  // <React.StrictMode>
  <Router basename={process.env.PUBLIC_URL}>
    <App />
  </Router>
  // </React.StrictMode>
);
