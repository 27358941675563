import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Error404 from 'components/common/Error404';
import Executions from 'components/mb-bots/bot-details/executions/Executions';
import CronJobs from 'components/mb-bots/bot-details/cron-jobs/CronJobs';
import Script from 'components/mb-bots/bot-details/Script';
import EnvVars from 'components/mb-bots/bot-details/EnvVars';
import Requirements from 'components/mb-bots/bot-details/Requirements';
import Panel from 'components/mb-bots/bot-details/panel/Panel';
import { getBot, getCronJobs } from 'helpers/requests/mb-bots';
import { handleUnexpectedStatusCode } from 'helpers/errors';

const Bot = () => {
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [bot, setBot] = useState({});
  const [cronJobs, setCronJobs] = useState([]);
  const { id } = useParams();

  let handleGetBot = async () => {
    const r = await getBot(id);
    if (r.success) {
      setBot(r.response.data);
      setLoading(false);
    } else {
      if (r.response.status === 404) {
        setNotFound(true);
      } else {
        handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
      }
    }
  };

  let handleGetCronJobs = async () => {
    const r = await getCronJobs(id);
    if (r.success) {
      setCronJobs(r.response.data);
    } else {
      handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
    }
  };

  useEffect(() => {
    handleGetBot();
    handleGetCronJobs();
  }, []);

  if (notFound) {
    return <Error404 />;
  }

  return (
    <>
      <Panel loading={loading} bot={bot} botId={id} />
      <CronJobs cronJobs={cronJobs} />

      {/* Rendering Executions only after we know for sure that the bot exists.
      Otherwise, 'Bot has no executions yet' message may flicker before Error404
      is shown. */}
      {!loading && (
        <>
          <Executions botId={id} />
          <Script value={bot.script} />
          <Requirements value={bot.requirements} />
          <EnvVars value={bot.env_vars} />
        </>
      )}
    </>
  );
};

export default Bot;
