import React from 'react';
import { Row } from 'react-bootstrap';
import CronJob from 'components/mb-bots/bot-details/cron-jobs/CronJob';

const CronJobs = ({ cronJobs }) => {
  return (
    cronJobs.length > 0 && (
      <Row>
        {cronJobs.map(cronJob => (
          <CronJob key={cronJob.id} cronJob={cronJob} />
        ))}
      </Row>
    )
  );
};

export default CronJobs;
