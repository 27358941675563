import React, { useEffect, useState } from 'react';
import { Card, Alert } from 'react-bootstrap';
import LogsModal from 'components/mb-bots/bot-details/logs/LogsModal';
import { getExecutions } from 'helpers/requests/mb-bots';
import { handleUnexpectedStatusCode } from 'helpers/errors';
import Header from 'components/mb-bots/bot-details/executions/Header';
import Table from 'components/mb-bots/bot-details/executions/Table';
import getColumns from 'components/mb-bots/bot-details/executions/get-columns';
import Loading from 'components/mb-bots/bot-details/executions/Loading';

const Executions = ({ botId }) => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedLogs, setSelectedLogs] = useState([]);
  const [executions, setExecutions] = useState([]);

  let handleGetExecutions = async () => {
    const r = await getExecutions(botId);
    if (r.success) {
      setExecutions(r.response.data);
    } else {
      handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
    }
    setLoading(false);
  };

  const handleViewLogsClick = logs => {
    setSelectedLogs(logs);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    handleGetExecutions();
  }, [botId]);

  return (
    <>
      <Card className="mb-3">
        <Card.Header>
          <Header />
        </Card.Header>
        <Card.Body className="p-0">
          {loading && <Loading />}
          {!loading && executions.length === 0 && (
            <Alert key={'info'} variant={'info'} className="mx-3">
              Bot has no executions yet.
            </Alert>
          )}
          {!loading && executions.length > 0 && (
            <Table
              columns={getColumns(handleViewLogsClick)}
              data={executions}
            />
          )}
        </Card.Body>
      </Card>
      <LogsModal
        logs={selectedLogs}
        show={showModal}
        onHide={handleCloseModal}
      />
    </>
  );
};

export default Executions;
