import React from 'react';
import { Link } from 'react-router-dom';
import { appPaths } from 'config';
import SoftBadge from 'components/common/SoftBadge';
import { fromISOInUTC } from 'helpers/time';

const getColumns = () => {
  return [
    {
      accessor: 'created',
      Header: 'Created',
      headerProps: { className: 'pe-7' },
      Cell: rowData => {
        const { created } = rowData.row.original;
        return <>{fromISOInUTC(created).toFormat('LLL dd, HH:mm')}</>;
      }
    },
    {
      accessor: 'full_name',
      Header: 'Full name',
      headerProps: { className: 'pe-7' }
    },

    {
      accessor: 'email',
      Header: 'Email',
      headerProps: { className: 'pe-7' }
    },
    {
      accessor: 'account',
      Header: 'Balance',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { account } = rowData.row.original;
        return (
          <>
            {account.suspended_for_nonpayment ? (
              <SoftBadge bg="danger" className="me-1">
                Suspended
              </SoftBadge>
            ) : (
              <SoftBadge bg="success" className="me-1">
                Active
              </SoftBadge>
            )}
            {account.balance}
          </>
        );
      }
    },
    {
      accessor: 'bot_set',
      Header: 'Bots',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { bot_set } = rowData.row.original;
        return (
          <>
            {bot_set.map(bot => (
              <div key={bot.id}>
                <Link to={appPaths.bots.detail.replace(':id', bot.id)}>
                  <strong>{bot.name}</strong>
                </Link>
              </div>
            ))}
          </>
        );
      }
    }
  ];
};
export default getColumns;
