import React, { useEffect, useRef } from 'react';
import { Card, Dropdown, Placeholder } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PanelHeader = ({ loading, bot }) => {
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  if (loading) {
    return (
      <Card.Header>
        <Placeholder as={Card.Text} animation="glow">
          <Placeholder xs={1} />
        </Placeholder>
      </Card.Header>
    );
  }

  return (
    <>
      <Card.Header className="d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <h5 className="fs-0 mb-0 d-flex align-items-center">{bot.name}</h5>
        </div>
        <div>
          <Dropdown align="end" className="btn-reveal-trigger d-inline-block">
            <Dropdown.Toggle split variant="falcon-primary" size="sm">
              <FontAwesomeIcon icon="ellipsis-h" className="fs--2" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="border py-0">
              <div className="py-2">
                {/* <Dropdown.Item
                  className="text-primary"
                  as={Link}
                >
                </Dropdown.Item> */}
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Card.Header>
    </>
  );
};

export default PanelHeader;
