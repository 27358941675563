import React, { useEffect, useState } from 'react';
import { getUsers } from 'helpers/requests/mb-users';
import { Card } from 'react-bootstrap';
import { handleUnexpectedStatusCode } from 'helpers/errors';
import Header from 'components/mb-users/user-list/Header';
import getColumns from 'components/mb-users/user-list/get-columns';
import Table from 'components/mb-users/user-list/Table';
import Loading from 'components/mb-users/user-list/Loading';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  let handleGetUsers = async () => {
    const r = await getUsers();
    if (r.success) {
      setUsers(r.response.data);
    } else {
      handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
    }
    setLoading(false);
  };

  useEffect(() => {
    handleGetUsers();
  }, []);

  return (
    <Card className="mb-3">
      <Card.Header>
        <Header />
      </Card.Header>
      <Card.Body className="p-0">
        {loading && <Loading />}
        {!loading && users.length > 0 && (
          <Table columns={getColumns()} data={users} />
        )}
      </Card.Body>
    </Card>
  );
};

export default Users;
