import React, { useState, useEffect } from 'react';
import InfoCard from 'components/mb-stats/generic/counter/InfoCard';
import { getBalancePurchases } from 'helpers/requests/mb-billing';

const BalancePurchases = () => {
  const [count, setCount] = useState(0);

  const handleGetBalancePurchases = async () => {
    const r = await getBalancePurchases();
    if (r.success) {
      setCount(r.response.data.length);
    }
  };

  useEffect(() => {
    handleGetBalancePurchases();
  }, []);

  return <InfoCard title={'Balance purchases'} count={count} />;
};

export default BalancePurchases;
