import React from 'react';
import SoftBadge from 'components/common/SoftBadge';
import { Link } from 'react-router-dom';
import {
  DefaultColumnFilter,
  SelectColumnFilter
} from 'components/common/advance-table/ColumnFilters';
import { fromISOInUTC } from 'helpers/time';

const getColumns = handleViewLogsClick => {
  const getSource = row => {
    return row.type === 'cron_job'
      ? `Scheduled - ${row.bot_executor_cron_job.name}`
      : 'Manual';
  };

  const getStatus = row => {
    switch (row.status) {
      case 'success':
        return 'Success';
      case 'failure':
        return 'Failure';
      case 'in_progress':
        return 'In progress';
      case 'termination':
        return 'Termination';
      default:
        return 'Unknown';
    }
  };

  const getStatusBadgeColor = row => {
    switch (row.status) {
      case 'success':
        return 'success';
      case 'failure':
        return 'danger';
      case 'in_progress':
        return 'warning';
      case 'termination':
        return 'danger';
      default:
        return 'info';
    }
  };

  const getStart = row => {
    const dt = fromISOInUTC(row.start);
    return dt.toFormat('yyyy-MM-dd HH:mm:ss.SSS');
  };

  const getFinish = row => {
    // When the execution is in progress, the finish time is null.
    if (row.finish === null) return '';
    const dt = fromISOInUTC(row.finish);
    return dt.toFormat('yyyy-MM-dd HH:mm:ss.SSS');
  };

  return [
    {
      accessor: row => getStatus(row),
      Header: 'Status',
      Filter: SelectColumnFilter,
      disableSortBy: true,
      headerProps: { className: 'pe-7' },
      Cell: rowData => {
        const row = rowData.row.original;
        return (
          <SoftBadge bg={getStatusBadgeColor(row)}>{getStatus(row)}</SoftBadge>
        );
      }
    },
    {
      accessor: row => getSource(row),
      Header: 'Source',
      Filter: SelectColumnFilter,
      disableSortBy: true,
      headerProps: { className: 'pe-7' },
      Cell: rowData => getSource(rowData.row.original)
    },
    {
      accessor: row => getStart(row),
      Header: 'Start',
      Filter: DefaultColumnFilter,
      headerProps: { className: 'pe-7' },
      Cell: rowData => {
        return <>{getStart(rowData.row.original)}</>;
      }
    },
    {
      accessor: row => getFinish(row),
      Header: 'Finish',
      Filter: DefaultColumnFilter,
      headerProps: { className: 'pe-7' },
      Cell: rowData => {
        return <>{getFinish(rowData.row.original)}</>;
      }
    },
    {
      Header: 'Logs',
      headerProps: { className: 'pe-7' },
      Cell: rowData => {
        const { logs } = rowData.row.original;
        return (
          <>
            <Link
              to="#"
              onClick={e => {
                e.preventDefault();
                handleViewLogsClick(logs);
              }}
            >
              <strong>View</strong>
            </Link>
          </>
        );
      }
    }
  ];
};
export default getColumns;
