export const version = '4.0.0';
export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg';
export const settings = {
  isFluid: false,
  isRTL: false,
  isDark: true,
  navbarPosition: 'top',
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: '$',
  isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
  navbarStyle: 'transparent',
  APIBaseURL: process.env.REACT_APP_API_BASE_URL,
  contactEmail: 'bakar@botfleet.ai'
};

export const genericErrorMessage =
  'Something went wrong. We could not process your request.';

export const errorCodes = {
  csrfFailure: 'csrf_failure',
  authenticationRequired: 'authentication_required',
  validationError: 'validation_error'
};
export const nonFieldErrorsKey = '__all__';

export const appPaths = {
  index: '/',
  errors: {
    notFound: '/errors/404'
  },
  auth: {
    login: '/auth/login'
  },
  settings: {
    profile: '/settings/profile'
  },
  bots: {
    detail: '/bots/:id'
  }
};

export const apiPaths = {
  appUsers: {
    users: {
      detail: '/app-users/users/me/'
    },
    auth: {
      csrf: '/app-users/auth/csrf/',
      status: '/app-users/auth/status/',
      login: '/app-users/auth/login/',
      logout: '/app-users/auth/logout/'
    }
  },
  appMbStats: {
    executions: {
      statusMetrics: '/app-mb_stats/executions/status-metrics/'
    },
    botBuilderJobs: {
      statusMetrics: '/app-mb_stats/bot-builder-jobs/status-metrics/'
    },
    botExecutorCronJobs: {
      statusMetrics: '/app-mb_stats/bot-executor-cron-jobs/status-metrics/'
    }
  },
  appMbUsers: {
    users: {
      list: '/app-mb_users/users/'
    }
  },
  appMbBilling: {
    balancePurchases: {
      list: '/app-mb_billing/balance-purchases/'
    }
  },
  appMbBots: {
    bots: {
      detail: id => `/app-mb_bots/bots/${id}/`
    },
    botExecutorCronJobs: {
      list: '/app-mb_bots/bot-executor-cron-jobs/'
    },
    botBuilderJobs: {
      latest: '/app-mb_bots/bot-builder-jobs/latest/'
    },
    executions: {
      list: `/app-mb_bots/executions/`
    },
    builds: {
      detail: id => `/app-mb_bots/builds/${id}/`
    }
  }
};

export default {
  version,
  navbarBreakPoint,
  topNavbarBreakpoint,
  settings,
  errorCodes,
  nonFieldErrorsKey,
  genericErrorMessage
};
