import React, { useContext } from 'react';
import CodeMirror from '@uiw/react-codemirror';
import { EditorState } from '@codemirror/state';
import { EditorView } from '@codemirror/view';
import { indentUnit } from '@codemirror/language';
import { python } from '@codemirror/lang-python';
import AppContext from 'contexts/AppContext';
import { tokyoNight, githubLight } from '@uiw/codemirror-themes-all';

function getLanguageExtensions(language) {
  switch (language) {
    case 'python':
      return [python(), indentUnit.of(' '.repeat(4))];
    case 'plaintext':
      return [];
    default:
      return [];
  }
}

function CodeEditor({ language, value, height, readOnly = false, onChange }) {
  const {
    config: { isDark }
  } = useContext(AppContext);

  const extensions = [
    ...getLanguageExtensions(language),
    EditorView.editable.of(!readOnly),
    EditorState.readOnly.of(readOnly)
  ];

  return (
    <CodeMirror
      value={value}
      height={height}
      extensions={extensions}
      onChange={onChange}
      theme={isDark ? tokyoNight : githubLight}
    />
  );
}

export default CodeEditor;
