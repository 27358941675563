import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import SoftBadge from 'components/common/SoftBadge';

const CronJob = ({ cronJob }) => {
  return (
    <Col xs={12} md={6} key={cronJob.id}>
      <Card className="mb-3">
        <Card.Body>
          <Row className="mb-3">
            <Col className="d-flex justify-content-between align-items-center">
              <h6 className="mb-0">Scheduled - {cronJob.name}</h6>
              <SoftBadge
                bg={cronJob.status === 'running' ? 'success' : 'warning'}
              >
                {cronJob.status === 'running' ? 'Running' : 'Paused'}
              </SoftBadge>
            </Col>
          </Row>
          <Row className="d-flex justify-content-between">
            <Col>
              <Card.Text>{cronJob.expression}</Card.Text>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default CronJob;
