import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const LogsModal = ({ logs, show, onHide, loading }) => {
  const noLogsMessage = <pre>No logs to show.</pre>;
  const loadingLogsMessage = <pre>Loading...</pre>;
  const logsLines = (
    <pre style={{ whiteSpace: 'pre-wrap' }}>
      {logs.map(({ log, time }) => `${time}: ${log}`).join('\n')}
    </pre>
  );
  return (
    <Modal show={show} onHide={onHide} fullscreen={true}>
      <Modal.Header className="d-flex justify-content-end">
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Header>
      <Modal.Body>
        {loading ? loadingLogsMessage : logs.length ? logsLines : noLogsMessage}
      </Modal.Body>
    </Modal>
  );
};

export default LogsModal;
