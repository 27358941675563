import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CountUp from 'react-countup';

const InfoCard = ({ title, count, viewLink }) => {
  return (
    <Card className="overflow-hidden">
      <Card.Body className="position-relative">
        <Row className="d-flex align-items-center">
          <Col sm={6}>
            <h6>{title}</h6>
            <div
              className={classNames(
                'text-info',
                'display-4 fs-4 mb-2 fw-normal font-sans-serif'
              )}
            >
              <CountUp
                start={0}
                end={count}
                duration={2.75}
                suffix={''}
                prefix={''}
                separator=","
                decimals={0}
                decimal="."
              />
            </div>
            {viewLink !== undefined && (
              <Link to={viewLink} className="fw-semi-bold fs--1 text-nowrap">
                View
                <FontAwesomeIcon
                  icon="angle-right"
                  className="ms-1"
                  transform="down-1"
                />
              </Link>
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default InfoCard;
