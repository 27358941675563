import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Placeholder } from 'react-bootstrap';
import SoftBadge from 'components/common/SoftBadge';
import { getLatestBotBuilderJob } from 'helpers/requests/mb-bots';
import { handleUnexpectedStatusCode } from 'helpers/errors';
import BuildStatus from 'components/mb-bots/bot-details/panel/BuildStatus';

const PanelBody = ({ loading, bot, botId }) => {
  const [latestBotBuilderJob, setLatestBotBuilderJob] = useState(null);

  let handleGetLatestBotBuilderJob = async () => {
    const r = await getLatestBotBuilderJob(botId);
    if (r.success) {
      setLatestBotBuilderJob(r.response.data);
    } else {
      handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
    }
  };

  useEffect(() => {
    handleGetLatestBotBuilderJob();
  }, []);

  if (loading) {
    return (
      <Card.Body>
        <Placeholder as={Card.Text} animation="glow">
          <Placeholder xs={1} />
          <Placeholder xs={1} className="ms-2" />
        </Placeholder>
      </Card.Body>
    );
  }

  return (
    <>
      <Card.Body>
        <Row className="d-flex justify-content-between">
          <Col xs={12} md={6}>
            <SoftBadge bg="info" className="me-2">
              Python {bot.python_version}
            </SoftBadge>
            {latestBotBuilderJob && (
              <BuildStatus latestBotBuilderJob={latestBotBuilderJob} />
            )}
          </Col>
        </Row>
      </Card.Body>
    </>
  );
};

export default PanelBody;
