import React from 'react';
import { Navigate } from 'react-router-dom';
import { useContext } from 'react';
import AuthContext from 'contexts/AuthContext';
import { appPaths } from 'config';

// Intended for pages that only authenticated users can access
export function PrivateRoute({ element }) {
  let { isAuthenticated } = useContext(AuthContext);
  return isAuthenticated ? (
    element
  ) : (
    <Navigate to={appPaths.auth.login} replace={true} />
  );
}

// Intended for pages where users perform authentication actions.
// If the user is already authenticated, they will be redirected to the home page.
export function AuthRoute({ element }) {
  let { isAuthenticated } = useContext(AuthContext);
  return isAuthenticated ? (
    <Navigate to={appPaths.index} replace={true} />
  ) : (
    element
  );
}
