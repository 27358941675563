import { ax } from 'helpers/requests/axios';
import { apiPaths } from 'config';

export async function getBot(id) {
  const EXPECTED_STATUS_CODE = 200;
  try {
    const response = await ax.get(apiPaths.appMbBots.bots.detail(id));
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function getCronJobs(botId) {
  const EXPECTED_STATUS_CODE = 200;
  try {
    const response = await ax.get(apiPaths.appMbBots.botExecutorCronJobs.list, {
      params: { bot_id: botId }
    });
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function getExecutions(botId) {
  const EXPECTED_STATUS_CODE = 200;
  try {
    const response = await ax.get(apiPaths.appMbBots.executions.list, {
      params: { bot_id: botId }
    });
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function getBuild(id) {
  const EXPECTED_STATUS_CODE = 200;
  try {
    const response = await ax.get(apiPaths.appMbBots.builds.detail(id));
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function getLatestBotBuilderJob(botId) {
  const EXPECTED_STATUS_CODE = 200;
  try {
    const response = await ax.get(apiPaths.appMbBots.botBuilderJobs.latest, {
      params: { bot_id: botId }
    });
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}
