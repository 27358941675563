import React, { useState, useEffect } from 'react';
import InfoCard from 'components/mb-stats/generic/counter-with-ratios/InfoCard';
import { getExecutionStatusMetrics } from 'helpers/requests/mb-stats';

const Executions = () => {
  const [success, setSuccess] = useState(0);
  const [failure, setFailure] = useState(0);
  const [inProgress, setInProgress] = useState(0);

  const handleGetExecutionStatusMetrics = async () => {
    const r = await getExecutionStatusMetrics();
    if (r.success) {
      const totals = r.response.data.reduce(
        (acc, item) => {
          acc.success += item.success;
          acc.inProgress += item.in_progress;
          acc.failure += item.failure;
          return acc;
        },
        { success: 0, inProgress: 0, failure: 0 }
      );
      setSuccess(totals.success);
      setFailure(totals.failure);
      setInProgress(totals.inProgress);
    }
  };

  useEffect(() => {
    handleGetExecutionStatusMetrics();
  }, []);

  const data = [
    { value: success, name: 'Success', color: 'success' },
    { value: failure, name: 'Failure', color: 'danger' },
    { value: inProgress, name: 'In progress', color: 'warning' }
  ];

  return <InfoCard title={'Executions'} data={data} radius={['100%', '85%']} />;
};

export default Executions;
