import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import AuthContext from 'contexts/AuthContext';
import { logoutUser } from 'helpers/requests/users';
import { useNavigate } from 'react-router-dom';
import Avatar from 'components/common/Avatar';
import avatar from 'assets/img/icons/avatar.png';
import { appPaths } from 'config';
import { handleUnexpectedStatusCode } from 'helpers/errors';

const ProfileDropdown = () => {
  let { setIsAuthenticated } = useContext(AuthContext);
  let navigate = useNavigate();

  const handleLogoutUser = async e => {
    e.preventDefault();
    const r = await logoutUser();
    if (r.success) {
      setIsAuthenticated(false);
      navigate(appPaths.auth.login);
    } else {
      handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
    }
  };

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        className="pe-0 ps-2 nav-link d-flex"
      >
        <Avatar src={avatar} size={'xl'} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item as={Link} to={appPaths.settings.profile}>
            Profile
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item as={Link} onClick={handleLogoutUser}>
            Logout
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
